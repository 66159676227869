import toastr from "toastr";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import React, { useState, useEffect } from "react";
import { render, unmountComponentAtNode } from "react-dom";
import SidePanel from "./components/SidePanel";
import { AssociatedTags } from "./alertForTags";
import CopyToClipboard from "react-copy-to-clipboard";
import AppMessages from "./Messages";

const Messages = AppMessages;

export const showConfirmWithUI = (obj) => {
  let text;
  const { data } = obj;
  if (data && Array.isArray(data) && data.length > 0) {
    text = `<div style="overflow-y: auto;max-height: 120px;">
          ${data.reduce((p, header) => p + `<b><li>${header}</li></b>`, "")}
          </div>`;
  }
  return new Promise(function (resolve, reject) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div>
            <div className="upperPart">
              <div className="react-confirm-alert">
                {/* close button for popup */}
                <div className="react-confirm-alert-body">
                  <h1>{obj.title}</h1>
                  <div
                    dangerouslySetInnerHTML={{ __html: text != undefined ? obj.msgText.concat(text) : obj.msgText }}
                  />
                </div>
              </div>

              <div className="lowerPart">
                {/* Cancel Button to close popup*/}
                {obj.closeButton ? (
                  <button
                    className="closeButton"
                    title="Close Alert"
                    onClick={() => {
                      // resolve(undefined);
                      resolve({ buttonClick: undefined });
                      onClose();
                    }}
                  >
                    Cancel
                  </button>
                ) : null}

                <div className="react-confirm-alert-button-group">
                  {/* Back/Cancle Button */}
                  {obj.alertType == "ConfirmationAlerts" ? (
                    <button
                      className="backCancle"
                      onClick={() => {
                        // resolve(false)
                        resolve({ buttonClick: false });
                        onClose();
                      }}
                    >
                      {obj.cancletext}
                    </button>
                  ) : null}

                  <button
                    className={`${obj.okTextClass}`}
                    onClick={() => {
                      let response = {};
                      let inputs = [...document.getElementsByTagName("input")];
                      inputs.forEach((input) => {
                        if (input.type === "checkbox") {
                          Object.assign(response, { [input.name]: input.checked });
                        } else {
                          Object.assign(response, { [input.name]: input.value });
                        }
                      });

                      // resolve(true);
                      resolve({ buttonClick: true, ...response });
                      onClose();
                    }}
                  >
                    {obj.okText}
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      }
    });
  });
};

//let notification;
// export const showConfirm1 = msg => {
//   return new Promise(function (resolve, reject) {
//     confirmAlert({
//       title: msg.title,
//       message: msg.msg_text,
//       buttons: [
//         {
//           label: msg.cancelText ? msg.cancelText : "No",
//           onClick: () => {
//             resolve(false);
//           }
//         },
//         {
//           label: msg.okText ? msg.okText : "Yes",
//           onClick: () => {
//             resolve(true);
//           }
//         }
//       ]
//     });
//   });
// };

// Custom show Confirm
export const showConfirm = (obj) => {
  let text;
  const { data, list, repMergeTagString } = obj;
  if (data && Array.isArray(data) && data.length > 0) {
    let repMergetagsHTML = repMergeTagString
      ? `<br/><div>${Messages.campaigns.disabledRepCheckMergeTags}</div><div><b>${repMergeTagString}</b></div>`
      : "";
    text = `<div style="overflow-y: auto;max-height: 120px;">
          ${data.reduce((p, header) => p + `<b><li>${header}</li></b>`, "")}
          </div>${repMergetagsHTML}`;
  }
  if (list && Array.isArray(list) && list.length > 0) {
    text = `<div style="overflow-y: auto;max-height: 200px;">
         ${list
           .map(
             (ele) => `<br/><b>${ele.title}</b><br/>
           ${ele.list.map((header, index) => `<p> ${index + 1}. ${header}</p>`).join("")}`
           )
           .join("")}
          </div>`;
  }
  return new Promise(function (resolve, reject) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div style={obj.width ? { width: obj.width } : null}>
            <div className="upperPart">
              <div className="react-confirm-alert">
                {/* close button for popup */}
                <div className={`react-confirm-alert-body ${obj.alertBodyWidth ? "confirm-alert-body-w-100" : ""}`}>
                  <h1>{obj.title}</h1>
                  <div
                    dangerouslySetInnerHTML={{ __html: text != undefined ? obj.msgText.concat(text) : obj.msgText }}
                  />
                </div>
              </div>

              <div className="lowerPart">
                {/* Cancel Button to close popup*/}
                {obj.closeButton ? (
                  <button
                    className="closeButton"
                    title="Close Alert"
                    onClick={() => {
                      resolve(undefined);
                      onClose();
                    }}
                  >
                    Cancel
                  </button>
                ) : null}

                <div className="react-confirm-alert-button-group">
                  {/* Back/Cancle Button */}
                  {obj.alertType == "ConfirmationAlerts" ? (
                    <button
                      className="backCancle"
                      onClick={() => {
                        resolve(false);
                        onClose();
                      }}
                    >
                      {obj.cancletext}
                    </button>
                  ) : null}

                  <button
                    className={`${obj.okTextClass}`}
                    onClick={() => {
                      resolve(true);
                      onClose();
                    }}
                  >
                    {obj.okText}
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      }
    });
  });
};

export const showTagsDeletion = (obj) => {
  const { campAssociatedTags, workflowAssociatedTags } = obj;

  return new Promise(function (resolve, reject) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div>
            <div className="upperPart">
              <div className="react-confirm-alert">
                {/* close button for popup */}
                <div className="react-confirm-alert-body">
                  {" "}
                  <h1>{obj.title}</h1>
                  <AssociatedTags
                    campAssociatedTags={campAssociatedTags}
                    workflowAssociatedTags={workflowAssociatedTags}
                  />
                </div>
              </div>

              <div className="lowerPart">
                {/* Cancel Button to close popup*/}
                {obj.closeButton ? (
                  <button
                    className="closeButton"
                    title="Close Alert"
                    onClick={() => {
                      resolve(undefined);
                      onClose();
                    }}
                  >
                    Cancel
                  </button>
                ) : null}

                <div className="react-confirm-alert-button-group">
                  {/* Back/Cancle Button */}
                  {obj.alertType == "ConfirmationAlerts" ? (
                    <button
                      className="backCancle"
                      onClick={() => {
                        resolve(false);
                        onClose();
                      }}
                    >
                      {obj.cancletext}
                    </button>
                  ) : null}

                  <button
                    className={`${obj.okTextClass}`}
                    onClick={() => {
                      resolve(true);
                      onClose();
                    }}
                  >
                    {obj.okText}
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      }
    });
  });
};

// Custom show Confirm

export const showAsyncAlert = (msg) => {
  return new Promise(function (resolve, reject) {
    confirmAlert({
      title: msg.title,
      message: msg.msg_text,
      buttons: [
        {
          label: "Ok",
          onClick: () => {
            resolve(true);
          }
        }
      ]
    });
  });
};

//custom toastr asynchronous
export const showCustomError = (msg) => {
  return new Promise(function (resolve, reject) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div id="toast-container" style={{ position: "relative", bottom: "150px" }} className="toast-to-center">
            <div className="toast toast-error" aria-live="assertive">
              <button
                onClick={() => {
                  resolve(undefined);
                  onClose();
                }}
                type="button"
                class="toast-close-button"
                role="button"
              >
                ×
              </button>
              <div className="toast-title">Error</div>
              <div className="toast-message">{msg ? msg : "Something went wrong."}</div>
            </div>
          </div>
        );
      }
    });
  });
};

export const showCustomInfo = (obj) => {
  return new Promise(function (resolve, reject) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div id="toast-container" style={{ position: "relative", bottom: "150px" }} className="toast-to-center">
            <div className="toast toast-info" aria-live="assertive">
              <button
                onClick={() => {
                  resolve(undefined);
                  onClose();
                }}
                type="button"
                class="toast-close-button"
                role="button"
              >
                ×
              </button>
              <div className="toast-title">Info</div>
              <div className="toast-message">
                The Job has been started. Depending upon the size of the Job, it will take time to complete.
                <a href="/backgroundJobs" style={{ color: "#fff", borderBottom: "1px solid #fff" }}>
                  click here
                </a>{" "}
                to see the progress.
              </div>
            </div>
          </div>
        );
      }
    });
  });
};

export const showCopyToClipboard = (obj) => {
  const { data, closeButton = true, flashMessage = () => {} } = obj;
  return new Promise(function (resolve, reject) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div style={obj.width ? { width: obj.width } : null}>
            <div className="upperPart">
              <div className="react-confirm-alert">
                {/* close button for popup */}
                <div className="react-confirm-alert-body">
                  <h1>URL Preview</h1>
                  <div className="input-group">
                    <input type="text" className="form-control" value={data} readOnly />
                    <CopyToClipboard text={data} onCopy={flashMessage}>
                      <div className="input-group-append pointer tab-relative" title="Copy URL to Clipboard">
                        <span className="input-group-text">
                          <i className="fa fa-clone" />
                        </span>
                      </div>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>

              <div className="lowerPart">
                <div className="react-confirm-alert-button-group">
                  {/* Back/Cancle Button */}
                  {closeButton ? (
                    <button
                      className="backCancle"
                      title="Close Alert"
                      onClick={() => {
                        resolve(undefined);
                        onClose();
                      }}
                    >
                      Close
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        );
      }
    });
  });
};

// export const showPrompt1 = msg => {
//   return new Promise(function (resolve, reject) {
//     let text_value = "";
//     confirmAlert({
//       customUI: ({ onClose }) => {
//         return (
//           <div className="react-confirm-alert">
//             <div className="react-confirm-alert-body">
//               <h1>{msg}</h1>
//               {/* <br/> */}
//               <input
//                 type="text"
//                 onChange={e => {
//                   text_value = e.target.value;
//                 }}
//               />
//               {/* <br/> */}
//               <div className="react-confirm-alert-button-group">
//                 <button
//                   onClick={() => {
//                     resolve({ text: text_value, button: "OK" });
//                     onClose();
//                   }}
//                 >
//                   Ok
//                 </button>
//                 <button
//                   onClick={() => {
//                     resolve({ text: "", button: "CANCEL" });
//                     onClose();
//                   }}
//                 >
//                   Cancel
//                 </button>
//               </div>
//             </div>
//           </div>
//         );
//       }
//     });
//   });
// };

// Custom  Showprompt
export const showPrompt = (obj) => {
  return new Promise((resolve) => {
    let divElement = document.createElement("div");
    document.body.appendChild(divElement);
    const onClose = () => {
      resolve({ text: "", button: "CANCEL" });
      unmountComponentAtNode(divElement);
      document.body.removeChild(divElement);
    };
    const onSave = (inputText) => {
      resolve({ text: inputText, button: "OK" });
      unmountComponentAtNode(divElement);
      document.body.removeChild(divElement);
    };
    render(<Prompt {...obj} onClose={onClose} onSave={onSave} />, divElement);
  });
};

export const Prompt = (props) => {
  const { label, header, buttonText, onClose, onSave, defaultInput = "" } = props;
  const [inputText, setInputText] = useState(defaultInput);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(inputText);
  };

  return (
    <SidePanel onClose={onClose} title={header} style={{ width: "600px" }}>
      <form onSubmit={handleSubmit}>
        <div style={{ padding: "50px" }}>
          <label>{label}</label>
          <input className="form-control mb-2" onChange={(e) => setInputText(e.target.value)} value={inputText} />
          <br />
          <button className="btn btn-md btn-blue" type="submit">
            {buttonText}
          </button>
        </div>
      </form>
    </SidePanel>
  );
};
// Custom Showprompt

export const MessageTypes = {
  Error: "error",
  Info: "info",
  Success: "success",
  Warning: "warning"
};

export const AlertVariants = {
  TOASTR: "toastr",
  MODAL: "modal",
  ASYNC: "async"
};

export const showAlert = (msg) => {
  toastrAlert(msg);
};

export const toastrAlert = (msg) => {
  toastr.options = {
    // progressBar: true,
    newestOnTop: false,
    closeButton: true,
    preventDuplicates: true,
    positionClass: "toast-top-center",
    timeOut: msg.msg_type === MessageTypes.Error ? 6000 : 3000,
    extendedTimeOut: 5000,
    ...msg
  };

  switch (msg.msg_type) {
    case MessageTypes.Error: {
      const title = msg.title === undefined ? "Error" : msg.title;
      toastr.error(msg.msg_text, title);
      break;
    }
    case MessageTypes.Success: {
      const title = msg.title === undefined ? "Success" : msg.title;
      toastr.success(msg.msg_text, title);
      break;
    }
    case MessageTypes.Warning: {
      const title = msg.title === undefined ? "Warning" : msg.title;
      toastr.warning(msg.msg_text, title);
      break;
    }
    case MessageTypes.Info: {
      const title = msg.title === undefined ? "Info" : msg.title;
      toastr.info(msg.msg_text, title);
      break;
    }
    default: {
    }
  }
};
